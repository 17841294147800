<template>
  <div class="w-full mt-10 mb-10">
    <Accordion
      :open="open"
      :disable="!open"
      key="step-1.2"
      header="1.2 Reporte de estadísticas de quejas finalizadas"
    >
      <BaseRadio
        ref="ar_type_12"
        name="ar_type_12"
        label="¿El DCF finalizó quejas durante el período reportado? (tenga en cuenta todas las quejas terminadas por rectificación, desistimiento, inadmisión y decisión emitida por el DCF)"
        @onChangeValueGetValue="(value) => onChangeRadio(value, 'acc_12')"
        :radioOptions="options.oYesOrNot"
        required="true"
        class="col-span-2 mb-10"
        v-model="mainData.step_1_2.DCF_finalizo_quejas_periodo_reportado"
        :disabled="soloPreview"
      />

      <div v-if="mainData.step_1_2.DCF_finalizo_quejas_periodo_reportado == 1">
        <div class="w-full mt-10 mb-10">
          <Accordion
            :open="true"
            :disable="false"
            key="step-1.2.1"
            header="1.2.1 Quejas terminadas por rectificación por parte de la entidad"
          >
            <BaseRadio
              ref="step_121"
              name="step_121"
              label="¿Se tienen quejas terminadas por rectificación?"
              @onChangeValueGetValue="
                (value) => onChangeRadio(value, 'step_121')
              "
              :radioOptions="options.oYesOrNot"
              required="true"
              class="col-span-2 mb-10"
              v-model="mainData.step_1_2._1_2_1_quejas_terminadas_rectificacion"
              :disabled="soloPreview"
            />

            <div
              v-if="
                mainData.step_1_2._1_2_1_quejas_terminadas_rectificacion == 1
              "
              class="mt-10"
            >
              <ProductReasonsWithTableField
                ref="pr_table_1_2_1_1"
                name="pr_table_1_2_1_1"
                :company_id="mainData.company.company_id"
                :labelProductCounter="'Número de quejas terminadas por rectificación según producto seleccionado'"
                :labelReasonsCounter="'Número de quejas terminadas por rectificación según motivo seleccionado'"
                :labelTotalCounter="'1.2.1.1. Número total de quejas terminadas por rectificación de la entidad vigilada antes de la decisión del DCF'"
                :urlGetCounter="'?insta_recepcion_id=3&rectificacion_queja_id=1'"
                :labelInstanceCounter="'Número de quejas registradas por terminación por rectificación de la entidad vigilada antes de la decisión del DCF'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_1_1_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2
                    ._1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_1_1_producto_motivo
                "
                :soloPreview="soloPreview"
              />

              <BaseTextarea
                class="col-start-1 col-span-2"
                ref="describe_1.2.1.3"
                id="describe_1.2.1.3"
                label="1.2.1.1. Presente y describa las situaciones, productos y tipologías de quejas en las cuales la entidad más rectifica"
                placeholder=""
                maxlength="1000"
                v-model="
                  mainData.step_1_2
                    ._1_2_1_1_resuma_situaciones_rectificación_entidad
                "
                :disabled="soloPreview"
              />

              <ProductReasonsWithTableField
                ref="pr_table_1_2_1_2"
                name="pr_table_1_2_1_2"
                :company_id="mainData.company.company_id"
                :labelProductCounter="'Número de quejas terminadas por rectificación según producto seleccionado'"
                :labelReasonsCounter="'Número de quejas terminadas por rectificación según motivo seleccionado'"
                :labelTotalCounter="'1.2.1.2. Número total de quejas terminadas por rectificación de la entidad vigilada después de la decisión del DCF'"
                :labelInstanceCounter="'Número de quejas registradas por terminación por rectificación de la entidad vigilada después de la decisión del DCF'"
                :urlGetCounter="'?insta_recepcion_id=3&rectificacion_queja_id=1'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_1_2_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2
                    ._1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_1_2_producto_motivo
                "
                :soloPreview="soloPreview"
              />

              <BaseTextarea
                class="col-start-1 col-span-2"
                ref="describe_1.2.1.3"
                id="describe_1.2.1.3"
                label="1.2.1.3. Resuma las situaciones objeto de rectificación por parte de la entidad, indicando la forma en que esta procede (priorice lo más representativo a juicio de la DCF, por impacto en los consumidores financieros o requerir acciones de mejora que ameriten el reporte a la SFC). Por favor no incluir casos ni quejas individuales."
                placeholder=""
                maxlength="1000"
                v-model="
                  mainData.step_1_2
                    ._1_2_1_3_resuma_situaciones_rectificación_entidad
                "
                :disabled="soloPreview"
              />
            </div>
          </Accordion>
        </div>

        <div class="w-full mt-10 mb-10">
          <Accordion
            :open="true"
            :disable="false"
            key="step-1.2.2"
            header="1.2.2 Quejas terminadas por desistimiento"
          >
            <BaseRadio
              ref="step_122"
              name="step_122"
              label="¿Se tienen quejas terminadas por desistimiento?"
              @onChangeValueGetValue="
                (value) => onChangeRadio(value, 'step_122')
              "
              :radioOptions="options.oYesOrNot"
              required="true"
              class="col-span-2 mb-10"
              v-model="mainData.step_1_2._1_2_2_quejas_terminadas_desistimiento"
              :disabled="soloPreview"
            />

            <div
              v-if="
                mainData.step_1_2._1_2_2_quejas_terminadas_desistimiento == 1
              "
            >
              <BaseInput
                ref="1.2.2.1  total_quejas_terminadas_inadmisión"
                type="number"
                label="1.2.2.1 Número total de quejas terminadas por desistimiento"
                required="true"
                v-model="
                  mainData.step_1_2
                    ._1_2_2_1_total_quejas_terminadas_por_desistimiento
                "
                :disabled="soloPreview"
              />

              <BaseTextarea
                class="col-start-1 col-span-2"
                ref="describe_1.2.2.2"
                id="describe_1.2.2.2"
                label="Presente y describa las situaciones, productos y tipologías de quejas en las cuales en mayor medida se presentan desistimientos por parte de los consumidores"
                placeholder=""
                maxlength="2000"
                required="true"
                v-model="
                  mainData.step_1_2
                    ._1_2_2_2_tipologia_indique_desistimiento_consumidores_financieros
                "
                :disabled="soloPreview"
              />

              <BaseTextarea
                class="col-start-1 col-span-2"
                ref="describe_1.2.2.2"
                id="describe_1.2.2.2"
                label="1.2.2.2 Indique las razones de desistimiento informadas por los consumidores financieros, priorizando las que la DCF considere más representativas"
                placeholder=""
                maxlength="2000"
                required="true"
                v-model="
                  mainData.step_1_2
                    ._1_2_2_2_indique_desistimiento_consumidores_financieros
                "
                :disabled="soloPreview"
              />
            </div>
          </Accordion>
        </div>

        <div class="w-full mt-10 mb-10">
          <Accordion
            :open="true"
            :disable="false"
            key="step-1.2.3"
            header="1.2.3 Quejas terminadas por inadmisión"
          >
            <BaseRadio
              ref="step_123"
              name="step_123"
              label="¿Se tienen quejas terminadas por inadmisión?"
              @onChangeValueGetValue="
                (value) => onChangeRadio(value, 'step_123')
              "
              :radioOptions="options.oYesOrNot"
              required="true"
              class="col-span-2 mb-10"
              v-model="mainData.step_1_2._1_2_3_quejas_terminadas_inadmision"
              :disabled="soloPreview"
            />

            <div
              v-if="mainData.step_1_2._1_2_3_quejas_terminadas_inadmision == 1"
            >
              <div class="grid grid-cols-2 gap-x-8 mb-10">
                <BaseInput
                  ref=" total_quejas_terminadas_inadmisión"
                  type="text"
                  label="1.2.3 Número total de quejas terminadas por inadmisión"
                  :required="true"
                  v-model="
                    mainData.step_1_2._1_2_3_total_quejas_terminadas_inadmision
                  "
                  :disabled="soloPreview"
                />
              </div>

              <BaseCheckboxVertical
                class="col-start-1 col-span-4 mb-10"
                label="1.2.3.1 Justificación de la inadmisión por parte del DCF"
                :value="
                  mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids
                "
                @notify-changes="onChangeSelectJustInadmision"
                :data="options.oJustInadmision"
                :disabled="soloPreview"
              />

              <div class="col-span-2"></div>

              <a-card
                v-if="isJustInadActive('1')"
                size="small"
                class="text-left col-span-2"
                title="1) Causal legal de exclusión de competencia"
              >
                <a-button
                  slot="extra"
                  type="link"
                  @click="
                    openAccordions.showJustInad1 = !openAccordions.showJustInad1
                  "
                >
                  Ver detalles
                  <a-icon
                    :type="openAccordions.showJustInad1 ? 'up' : 'down'"
                  />
                </a-button>

                <div v-if="openAccordions.showJustInad1">
                  <BaseNumber
                    v-model="
                      mainData.step_1_2
                        ._1_2_3_1_causal_legal_numero_total_solicitudes
                    "
                    ref="num_12__numero_total_solicitudes"
                    label="Número total de justificación seleccionada."
                    placeholder="Ej: 123"
                    :disabled="soloPreview"
                  />

                  <a-collapse class="mt-6">
                    <a-collapse-panel key="1" header="Causales">
                      <p
                        slot="extra"
                        class="text-opacity-80 text-left"
                        :class="
                          mainData.step_1_2
                            ._1_2_3_1_causal_legal_numero_total_solicitudes !=
                          totalCausales_1_2_1
                            ? 'text-red-500'
                            : 'text-green-500'
                        "
                      >
                        Total: {{ totalCausales_1_2_1 }}
                        {{
                          mainData.step_1_2
                            ._1_2_3_1_causal_legal_numero_total_solicitudes !=
                          totalCausales_1_2_1
                            ? "(No coinciden los totales)"
                            : ""
                        }}
                      </p>
                      <a-table
                        size="small"
                        :pagination="false"
                        :row-selection="rowSelection"
                        :columns="columnsCausal"
                        :data-source="options.oTipoJustInCausaLegal"
                      >
                        <template
                          slot="labelName"
                          slot-scope="text, record"
                          :disabled="soloPreview"
                        >
                          <a class="flex text-left">{{ text }}</a>
                        </template>
                        <template
                          class="p-0"
                          slot="count"
                          slot-scope="text, record"
                        >
                          <BaseNumber
                            v-if="isRowSelected(record.id)"
                            :value="valueCausal(record.id)"
                            @notify-change="updateDetail(record.id, $event)"
                            label="Digita una cantidad"
                            ref="num_31_numero_total_solicitudes"
                            :disabled="soloPreview"
                            class="col-start-1 col-span-2 mt-0 pt-0"
                            placeholder="Ej: 123"
                            :mt_disabled="true"
                          />
                        </template>
                      </a-table>
                    </a-collapse-panel>
                  </a-collapse>

                  <BaseRadio
                    v-model="
                      mainData.step_1_2
                        ._1_2_3_1_causal_legal_aplica_producto_motivo
                    "
                    ref="opt_causal_legal_aplica_producto_motivo"
                    class="col-start-1 col-span-2"
                    label="¿Aplica producto y motivo?"
                    :radioOptions="options.oYesOrNot"
                    :disabled="soloPreview"
                  />

                  <BaseTextarea
                    v-if="causalLegalIncludeOtra()"
                    v-model="
                      mainData.step_1_2._1_2_3_1_causal_legal_otra_descripcion
                    "
                    class="col-start-1 col-span-4"
                    ref="describe"
                    id="describe"
                    label="Descripción"
                    placeholder="Mensaje"
                    maxlength="50"
                    :disabled="soloPreview"
                  />

                  <ProductReasonsWithTableField
                    ref="pr_table_1_2_3_1"
                    name="pr_table_1_2_3_1"
                    :company_id="mainData.company.company_id"
                    v-if="
                      mainData.step_1_2
                        ._1_2_3_1_causal_legal_aplica_producto_motivo == 1
                    "
                    :labelReasonsCounter="'Número de quejas terminadas por inadmisión según motivo seleccionado'"
                    :labelProductCounter="'Número de quejas terminadas por inadmisión según producto seleccionado'"
                    :products_reasons.sync="
                      mainData.step_1_3._1_2_3_1_producto_motivo
                    "
                    :hideTopInputs="true"
                    :soloPreview="soloPreview"
                  />

                  <BaseTextarea
                    v-if="
                      mainData.step_1_2
                        ._1_2_3_1_justificacion_inadmision_causal_legal_aplica_producto_motivo
                    "
                    v-model="mainData.step_1_2._1_2_3_1_descripcion"
                    class="col-start-1 col-span-4"
                    ref="describe"
                    id="describe"
                    label="Descripción"
                    placeholder="Mensaje"
                    maxlength="1000"
                    :disabled="soloPreview"
                  />
                </div>
              </a-card>
              <a-card
                v-if="isJustInadActive('2')"
                size="small"
                class="text-left col-span-2"
                title="2) Se refiere a otra entidad vigilada"
              >
                <a-button
                  slot="extra"
                  type="link"
                  @click="
                    openAccordions.showJustInad2 = !openAccordions.showJustInad2
                  "
                >
                  Ver detalles
                  <a-icon
                    :type="openAccordions.showJustInad2 ? 'up' : 'down'"
                  />
                </a-button>
                <div v-if="openAccordions.showJustInad2">
                  <BaseNumber
                    v-model="
                      mainData.step_1_2
                        ._1_2_3_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes
                    "
                    ref="num_12_numero_total_solicitudes"
                    label="Número total de quejas asociadas a la justificación seleccionada"
                    class="col-start-1 col-span-2"
                    placeholder="Ej: 123"
                    :disabled="soloPreview"
                  />
                </div>
              </a-card>
              <a-card
                v-if="isJustInadActive('3')"
                size="small"
                class="text-left col-span-2"
                title="3) Solicitud incompleta, no clara, lenguaje soez o agresivo"
              >
                <a-button
                  slot="extra"
                  type="link"
                  @click="
                    openAccordions.showJustInad3 = !openAccordions.showJustInad3
                  "
                >
                  Ver detalles
                  <a-icon
                    :type="openAccordions.showJustInad3 ? 'up' : 'down'"
                  />
                </a-button>
                <div v-if="openAccordions.showJustInad3">
                  <BaseNumber
                    v-model="
                      mainData.step_1_2
                        ._1_2_3_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes
                    "
                    ref="num_12_numero_total_solicitudes"
                    label="Número total de solicitudes inadmitidas asociadas a la justificación seleccionada."
                    class="col-start-1 col-span-2"
                    placeholder="Ej: 123"
                    :disabled="soloPreview"
                  />
                </div>
              </a-card>
              <a-card
                v-if="isJustInadActive('4')"
                size="small"
                class="text-left col-span-2"
                title="4) Otra"
              >
                <a-button
                  slot="extra"
                  type="link"
                  @click="
                    openAccordions.showJustInad4 = !openAccordions.showJustInad4
                  "
                >
                  Ver detalles
                  <a-icon
                    :type="openAccordions.showJustInad4 ? 'up' : 'down'"
                  />
                </a-button>
                <div v-if="openAccordions.showJustInad4">
                  <BaseNumber
                    v-model="
                      mainData.step_1_2
                        ._1_2_3_1_justificacion_inadmision_otra_numero_total_solicitudes
                    "
                    ref="num_12_numero_total_solicitudes"
                    label="Número total de inadmitidas asociadas a la justificación seleccionada."
                    class="col-start-1 col-span-2"
                    placeholder="Ej: 123"
                    :disabled="soloPreview"
                  />
                  <!-- <BaseTextarea
                                        v-model="mainData.step_1_2._1_2_3_1_justificacion_inadmision_otra_descripcion"
                                        class="col-start-1 col-span-4" ref="describe" id="describe"
                                        label="Informe brevemente y de manera consolidada las principales temáticas de las solicitudes de conciliación que fueron inadmitidas por la DCF. (Por favor no incluir casos individuales)"
                                        placeholder="Escribe la descripción..." maxlength="50"
                                        :disabled="soloPreview" /> -->
                </div>
              </a-card>

              <BaseTextarea
                class="mt-10 col-start-1 col-span-2"
                ref="describe_1.2.2.2"
                id="describe_1.2.2.2"
                label="1.2.3.2 Resuma las situaciones que hayan sido objeto de inadmisión por parte de la DCF, priorizando las más representativas. No incluir casos ni situaciones particulares"
                placeholder=""
                maxlength="2000"
                v-model="
                  mainData.step_1_2._1_2_3_2_resuma_situaciones_inadmisión_DCF
                "
                :disabled="soloPreview"
              />
            </div>
          </Accordion>
        </div>

        <div class="w-full mt-10 mb-10">
          <Accordion
            :open="true"
            :disable="false"
            key="step-1.2.4"
            header="1.2.4 Quejas terminadas por decisión emitida por el DCF"
            class="w-full"
          >
            <BaseRadio
              ref="step_124"
              name="step_124"
              label="¿Se tienen quejas terminadas por decisión emitida por el DCF?"
              @onChangeValueGetValue="
                (value) => onChangeRadio(value, 'step_124')
              "
              :radioOptions="options.oYesOrNot"
              required="true"
              class="col-span-2 mb-10"
              v-model="mainData.step_1_2._1_2_4_quejas_terminadas_emitida_DCF"
              :disabled="soloPreview"
            />

            <div
              v-if="mainData.step_1_2._1_2_4_quejas_terminadas_emitida_DCF == 1"
            >
              <ProductReasonsWithTableField
                ref="pr_table_1_2_4_1"
                name="pr_table_1_2_4_1"
                :company_id="mainData.company.company_id"
                :labelReasonsCounter="'Número de quejas terminadas por decisión emitida por el DCF según motivo seleccionado'"
                :labelProductCounter="'Número de quejas terminadas por decisión emitida por el DCF según producto seleccionado'"
                :labelTotalCounter="'1.2.4.1 Número total de quejas terminadas por decisión emitida por el DCF'"
                :labelInstanceCounter="'Número de quejas registradas terminadas por desistimiento'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_4_1_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2
                    ._1_2_4_1_total_quejas_terminadas_decision_emitida_DCF
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_4_1_producto_motivo
                "
                :urlGetCounter="'?insta_recepcion_id=3&admision_id=2'"
                :hideInstanceCounter="true"
                :soloPreview="soloPreview"
              />

              <h2 class="mt-20 text-[#5C5C5C] text-lg font-semibold text-left">
                1.2.5 Decisiones del DCF emitidas a favor de la entidad
              </h2>

              <BaseRadio
                ref="step_125"
                name="step_125"
                label=""
                @onChangeValueGetValue="
                  (value) => onChangeRadio(value, 'step_125')
                "
                :radioOptions="options.doOrDoNot"
                required="true"
                class="mt-20 mb-10"
                v-model="
                  mainData.step_1_2._1_2_5_decisiones_DCF_emitidas_favor_entidad
                "
                :disabled="soloPreview"
              />

              <ProductReasonsWithTableField
                v-if="
                  mainData.step_1_2
                    ._1_2_5_decisiones_DCF_emitidas_favor_entidad == 1
                "
                ref="pr_table_1_2_5"
                name="pr_table_1_2_5"
                :company_id="mainData.company.company_id"
                :labelReasonsCounter="'Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según motivo seleccionado'"
                :labelProductCounter="'Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según producto seleccionado'"
                :labelTotalCounter="'Número total de decisiones emitidas a favor de la entidad'"
                :labelInstanceCounter="'Número total registrado de decisiones emitidas a favor de la entidad'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_5_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2._1_2_5_total_decision_emitida_favor_entidad
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_5_producto_motivo
                "
                :urlGetCounter="'?insta_recepcion_id=3&a_favor_de_id=3'"
                :hideInstanceCounter="true"
                :soloPreview="soloPreview"
              />

              <BaseTextarea
                v-if="showStep.step_125"
                class="mt-10"
                ref="describe_1.2.5"
                id="describe_1.2.5"
                label="Resuma las situaciones objeto de decisión a favor de la entidad, destacando aquellas buenas prácticas que la defensoría haya identificado en el proceder de la vigilada. Sin hacer referencia a casos particulares"
                placeholder=""
                maxlength="1000"
                v-model="
                  mainData.step_1_2
                    ._1_2_5_resuma_situaciones_decisión_favor_entidad
                "
                :disabled="soloPreview"
              />

              <div class="w-full">
                <h2
                  class="mt-20 text-[#5C5C5C] text-lg font-semibold text-left"
                >
                  1.2.5.1 Decisiones emitidas a favor del consumidor financiero
                  (total y parcialmente favorables)
                </h2>
              </div>

              <!-- Totalmente favorable - condicional. -->
              <BaseRadioConditional
                ref="step_1251"
                name="step_1251"
                label="Decisiones del DCF
              totalmente favorables al consumidor financiero"
                @onChangeValueGetValue="
                  (value) => onChangeRadio(value, 'step_1251')
                "
                :radioOptions="options.TotalF"
                required="true"
                class="mt-20 mb-10"
                v-model="
                  mainData.step_1_2
                    ._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero
                "
              />

              <ProductReasonsWithTableField
                v-if="
                  mainData.step_1_2
                    ._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero ==
                  1
                "
                ref="pr_table_1_2_5_1"
                name="pr_table_1_2_5_1"
                :company_id="mainData.company.company_id"
                :labelReasonsCounter="'Número total de decisiones totalmente favorables al consumidor financiero según motivo seleccionado'"
                :labelProductCounter="'Número total de decisiones totalmente favorables al consumidor financiero según producto seleccionado'"
                :labelTotalCounter="'Número total de decisiones totalmente favorables al consumidor financiero'"
                :labelInstanceCounter="'Número total registrado de decisiones totalmente favorables al consumidor financiero'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_5_1_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2
                    ._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_5_1_producto_motivo
                "
                :urlGetCounter="'?insta_recepcion_id=3&a_favor_de_id=1'"
                :hideInstanceCounter="true"
                :soloPreview="soloPreview"
              />

              <BaseTextarea
                v-if="showStep.step_1251"
                class="mt-10"
                ref="describe_1.2.51"
                id="describe_1.2.51"
                label="Resuma la forma en que la entidad procede frente a las situaciones objeto de decisión totalmente a favor del consumidor financiero (priorice lo más representativo por impacto en los consumidores o que requiera acciones de mejora). Por favor no incluir casos ni quejas individuales."
                placeholder=""
                maxlength="1000"
                v-model="
                  mainData.step_1_2
                    ._1_2_5_1_resuma_situaciones_decision_totalmente_favor_consumidor_financiero
                "
                :disabled="soloPreview"
              />

              <!-- Parcialmente favorable - condicional. -->
              <BaseRadioConditional
                ref="step_1252"
                name="step_1252"
                label="1.2.5.2 Decisiones del DCF parcialmente favorables al consumidor financiero"
                @onChangeValueGetValue="
                  (value) => onChangeRadio(value, 'step_1252')
                "
                :radioOptions="options.ParcialF"
                required="true"
                class="mt-20 mb-10"
                v-model="
                  mainData.step_1_2
                    ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero
                "
              />

              <ProductReasonsWithTableField
                v-if="
                  mainData.step_1_2
                    ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero ==
                  1
                "
                ref="pr_table_1_2_5_2"
                name="pr_table_1_2_5_2"
                :company_id="mainData.company.company_id"
                :labelReasonsCounter="'Número total de decisiones parcialmente favorables según motivo seleccionado'"
                :labelProductCounter="'Número total de decisiones parcialmente favorables según producto seleccionado'"
                :labelTotalCounter="'Número total de decisiones parcialmente favorables al consumidor financiero'"
                :labelInstanceCounter="'Número total de decisiones parcialmente favorables registradas'"
                :instance-counter.sync="
                  mainData.step_1_2._1_2_5_2_instance_counter
                "
                :total-counter.sync="
                  mainData.step_1_2
                    ._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero
                "
                :products_reasons.sync="
                  mainData.step_1_2._1_2_5_2_producto_motivo
                "
                :urlGetCounter="'?insta_recepcion_id=3&a_favor_de_id=2'"
                :hideInstanceCounter="true"
                :soloPreview="soloPreview"
              />

              <BaseTextarea
                v-if="
                  mainData.step_1_2
                    ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero ==
                  1
                "
                class="mt-10"
                ref="describe_1.2.52"
                id="describe_1.2.52"
                label="Resuma la forma en que la entidad procede frente a las situaciones objeto de decisión parcialmente a favor del consumidor financiero (priorice lo más representativo por impacto en los consumidores, o que requiera acciones de mejora). Por favor no incluir casos ni quejas individuales"
                placeholder=""
                maxlength="1000"
                v-model="
                  mainData.step_1_2
                    ._1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero
                "
                :disabled="soloPreview"
              />
            </div>
          </Accordion>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import ProductReasons from "../product_reasons_funtional.vue";
import TemplateProductInputs from "../template_product_inputs.vue";
import ProductReasonField from "../../../../../annual_report_v2/presentation/components/ProductReasonsField";
import ProductReasonsWithTableField from "../../../../../annual_report_v2/presentation/components/ProductReasonsWithTableField";
import jOptions from "../../../../domain/entities/justification_options";
import exOptions from "../../../../domain/entities/exclutions_options";
import { isEqual, isEmpty } from "lodash";
import BtnAddProductReason from "../btn_add_product_reason.vue";
import BaseRadioConditional from "../../../../../../components/UI/BaseInput/BaseRadioConditional.vue";

const columnsCausal = [
  {
    title: "",
    dataIndex: "labelName",
    scopedSlots: { customRender: "labelName" },
  },
  {
    title: "",
    dataIndex: "count",
    scopedSlots: { customRender: "count" },
  },
];

export default {
  components: {
    TemplateProductInputs,
    Accordion,
    BaseRadioConditional,
    ProductReasons,
    BtnAddProductReason,
    ProductReasonField,
    ProductReasonsWithTableField,
  },
  props: {
    soloPreview: {
      type: Boolean,
      default: false,
    },
    mainData: {
      company: {},
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnsCausal: columnsCausal,
      openAccordions: {
        acc_3: true,
        acc_31: false,
        acc_32: false,
        acc_33: false,
        acc_34: false,
        showJustInad1: false,
        showJustInad2: false,
        showJustInad3: false,
        showJustInad4: false,
      },
      showStep: {
        acc_12: false,
        step_121: false,
        step_122: false,
        step_123: false,
        step_1231_1: false,
        step_1231_1_mp: false,
        step_1231_4: false,
        step_1231_1_j: false,
        step_124: false,
        step_125: false,
        step_1251: false,
        step_1252: false,
      },
      options: {
        oYesOrNot: [
          { id: 1, name: "Sí", boolValue: true },
          { id: 2, name: "No", boolValue: false },
        ],
        doOrDoNot: [
          { id: 1, name: "Aplica", boolValue: true },
          { id: 2, name: "No Aplica", boolValue: false },
        ],
        TotalF: [
          {
            id: 1,
            name: "Aplica",
            boolValue: true,
            conditional: false,
          },
          {
            id: 2,
            name: "No aplica",
            boolValue: false,
            conditional: false,
          },
        ],
        ParcialF: [
          {
            id: 1,
            name: "Aplica",
            boolValue: true,
            conditional: false,
          },
          {
            id: 2,
            name: "No aplica",
            boolValue: false,
            conditional: false,
          },
        ],
        oJustInadmision: [
          { id: "1", labelName: "1) Causal legal de exclusión de competencia" },
          { id: "2", labelName: "2) Se refiere a otra entidad vigilada" },
          {
            id: "3",
            labelName:
              "3) Solicitud incompleta, no clara, lenguaje soez o agresivo",
          },
          { id: "4", labelName: "4) Otra" },
        ],
        oTipoJustInCausaLegal: [
          {
            id: "1",
            labelName:
              "a. No corresponde al giro ordinario de las operaciones de la entidad",
          },
          {
            id: "2",
            labelName:
              "b. Se refiere al vínculo laboral entre la entidad y sus empleados o contratistas",
          },
          {
            id: "3",
            labelName: "c. Se deriva de condición de accionista de la entidad",
          },
          {
            id: "4",
            labelName:
              "d. Se refiere al reconocimiento de las prestaciones y de las pensiones de invalidez, de vejez y de sobrevivientes",
          },
          {
            id: "5",
            labelName:
              "e. Se encuentra en trámite judicial o arbitral o hayan sido resueltas en estas vías",
          },
          {
            id: "6",
            labelName:
              "f. Corresponde a la decisión sobre la prestación de un servicio o producto",
          },
          {
            id: "7",
            labelName:
              "g. Se refieren a hechos sucedidos con tres (3) años o más de anterioridad",
          },
          {
            id: "8",
            labelName:
              "h. Han sido objeto de decisión previa por parte del Defensor por tener mismos hechos y afectar a las mismas partes",
          },
          { id: "9", labelName: "i. Cuantía supera los cien (100) SMLMV" },
          { id: "10", labelName: "j. Otra" },
        ],
        justificationOptions: [],
        exclutionOptions: [],
      },
    };
  },
  created() {
    this.options.justificationOptions = jOptions;
    this.options.exclutionOptions = exOptions;
  },
  methods: {
    valueCausal(id) {
      const item =
        this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.find(
          (element) => element.id === id
        );

      if (item) {
        return item.count;
      } else {
        console.error("No se encontró un elemento con el id:", id);
        return null;
      }
    },
    updateDetail(key, value) {
      const detailIndex =
        this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.findIndex(
          (detail) => detail.id === key
        );
      if (detailIndex >= 0) {
        this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail[
          detailIndex
        ].count = value;
      } else {
        this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.push(
          { id: key, count: value }
        );
      }
      this.mainData = {
        ...this.mainData,
      };

      this.$forceUpdate();
    },
    isRowSelected(key) {
      return this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion?.includes(
        key
      );
    },
    add(key) {
      this.$refs[key].addProductReasons();
    },
    causalLegalIncludeOtra() {
      const item =
        this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.findIndex(
          (detail) => detail.id == "10"
        );
      return item >= 0;

      // if (!isEmpty(this.mainData.step_1_2._1_2_3_1_justificacion_inadmision_causal_legal_tipo_justificacion)) {
      //     return this.mainData.step_1_2._1_2_3_1_justificacion_inadmision_causal_legal_tipo_justificacion.includes('10')
      // } else {
      //     return false;
      // }
    },
    isJustInadActive(value) {
      if (
        !isEmpty(
          this.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids
        )
      ) {
        return this.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids.includes(
          value
        );
      } else {
        return false;
      }
    },
    onChangeSelectTipoJustInCausaLegal(values) {
      // let data = values.map((val) => val.id)

      // if (!isEqual(data, this.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids)) {
      //     this.mainData.step_1_2._1_2_3_1_justificacion_inadmision_causal_legal_tipo_justificacion = data
      // }

      if (
        !this.mainData.step_1_2._1_2_3_1_justificacion_inadmision_causal_legal_tipo_justificacion.includes(
          values
        )
      ) {
        this.mainData.step_1_2._1_2_3_1_justificacion_inadmision_causal_legal_tipo_justificacion.push(
          values
        );
      }

      this.$forceUpdate();
    },
    onChangeSelectJustInadmision(values) {
      if (
        !isEqual(
          values,
          this.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids
        )
      ) {
        this.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids =
          values;
      }
      this.$forceUpdate();
    },
    getProductReasons(value, key) {
      if (value.clave === key) {
        delete value.clave;
        this.mainData.step_1_2[key] = [...value];
      }
    },
    onChangeRadio(value, key) {
      // key === "step_1251" && !value.boolValue
      if (key === "step_1251") {
        if (value.id == 1) {
          // this.options.TotalF[1].conditional = false;
          this.options.ParcialF[0].conditional = false;
          this.options.ParcialF[1].conditional = false;
        } else {
          this.options.TotalF[0].conditional = false;
          this.options.TotalF[1].conditional = false;
          this.options.ParcialF[1].conditional = true;
        }
      }

      // key === "step_1252" && !value.boolValue
      if (key === "step_1252") {
        if (value.id == 1) {
          this.options.TotalF[0].conditional = false;
          this.options.TotalF[1].conditional = false;
        } else {
          this.options.TotalF[0].conditional = false;
          this.options.TotalF[1].conditional = true;
        }
      }

      this.showStep[key] = value.boolValue;
    },
    onChangeSelect(value, key) {
      if (Array.isArray(key)) {
        if (value.id === 1) {
          this.showStep.step_1231_1 = value.boolValue;
          this.showStep.step_1231_4 = false;
          this.showStep.step_1231_1_j = false;
        }
        if (value.id === 4) {
          this.showStep.step_1231_4 = value.boolValue;
          this.showStep.step_1231_1_j = false;
          this.showStep.step_1231_1 = false;
        }
      } else {
        this.showStep[key] = value.boolValue;
      }
    },
    notifyChanges() {
      let error = false;
      let refs = { ...this.$refs };

      for (let ref in refs) {
        if (
          (refs[ref] && refs[ref].required) ||
          refs[ref]?.invalid
        ) {
          if (refs[ref].value === undefined || refs[ref].value === null || refs[ref].value === '') {   
            refs[ref].$el.scrollIntoView({ behavior: 'smooth' });
            refs[ref].setInvalid(true);
            error = true;
          }
        }
      }
      return { error: error };
    },
  },
  computed: {
    totalCausales_1_2_1() {
      return this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.reduce(
        (sum, item) => {
          return sum + Number(item.count);
        },
        0
      );
    },
    rowSelection() {
      const selectedRowKeys = this.options.oTipoJustInCausaLegal
        .map((objeto, index) =>
          this.mainData.step_1_2[
            "_1_2_3_1_causal_legal_tipo_justificacion"
          ]?.includes(objeto.id)
            ? index
            : null
        )
        .filter((index) => index !== null);

      return {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion = [];
          selectedRows.forEach((element) => {
            this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion?.push(
              element.id
            );
          });

          const updatedDetail = [];

          this.options.oTipoJustInCausaLegal.forEach((element) => {
            if (
              this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion?.includes(
                element.id
              )
            ) {
              const justDetail =
                this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail?.find(
                  (detail) => detail.id === element.id
                );

              updatedDetail.push({
                id: element.id,
                labelName: element.labelName,
                count: justDetail?.count ?? null,
              });
            }
          });

          this.mainData.step_1_2._1_2_3_1_causal_legal_tipo_justificacion_detail =
            updatedDetail;

          this.mainData = {             
            ...this.mainData,
          };

          this.$forceUpdate();
        },
        getCheckboxProps: (record) => ({
          props: {
            disable: false,
            name: record.name,
            defaultChecked: record.isChecked ?? false,
          },
        }),
      };
    },
  },
};
</script>