<template>
  <div>
    <Accordion
      :open="openAccordions.acc_1"
      :disable="false"
      key="astep1"
      class="px-0"
      header="1. De la función de atención y resolución de quejas"
    >
      <div class="w-full">
        <BaseRadio
          ref="ar_type_11"
          name="ar_type_11"
          label="¿El DCF recibió quejas de los consumidores financieros contra la entidad en el período reportado?"
          @onChangeValueGetValue="(value) => onChangeRadio(value, 'acc_11')"
          :radioOptions="options.oYesOrNot"
          required="true"
          class="col-span-2"
          v-model="
            initialData.step_1_1
              .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado
          "
          :disabled="soloPreview"
        />

        <Step11
          ref="step11"
          v-if="
            initialData.step_1_1
              .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado ==
            1
          "
          :open="
            initialData.step_1_1
              .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado ==
            1
          "
          :mainData="initialData"
          :soloPreview="soloPreview"
        />

        <Step12
          ref="step12"
          :open="openAccordions.acc_12"
          :mainData="initialData"
          :soloPreview="soloPreview"
        />

        <Step13
          ref="step13"
          :open="openAccordions.acc_13"
          :mainData="initialData"
          :soloPreview="soloPreview"
          :company="company"
        />
      </div>
    </Accordion>
  </div>
</template>

<script>
import Step11 from "../components/step1_detail/step_1.1.vue";
import Step12 from "../components/step1_detail/step_1.2.vue";
import Step13 from "../components/step1_detail/step_1.3.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";

export default {
  components: {
    Accordion,
    Step11,
    Step12,
    Step13,
  },
  props: {
    soloPreview: {
      type: Boolean,
      default: false,
    },
    initialData: {},
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hasChanged: false,
      isFirstTime: true,
      openAccordions: {
        acc_1: true,
        acc_11: false,
        acc_12: true,
        acc_13: true,
      },
      options: {
        oYesOrNot: [
          { id: 1, name: "Sí", boolValue: true },
          { id: 2, name: "No", boolValue: false },
        ],
      },
    };
  },
  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          this.mainData = newVal;
          if (!this.isFirstTime && !this.hasChanged) {
            this.hasChanged = true;
            console.log("mainData 3 hasChanged:", this.hasChanged);
          }
          this.isFirstTime = false;
        }
      },
    },
  },
  created() {
    this.mainData = {
      ...this.initialData,
    };
    console.log("created ,mainData", this.mainData);

    if (this.soloPreview) {
      this.openAccordions.acc_11 = true;
    }
  },
  methods: {
    onChangeRadio(value, key) {
      console.log(
        this.initialData.step_1_1
          .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado
      );
      this.openAccordions[key] = value.boolValue;
    },
    notifyChanges() {
      let error = false;
      let refs = { ...this.$refs };

      for (let ref in refs) {
        if ((refs[ref] && refs[ref].required) || refs[ref]?.invalid) {
          if (refs[ref].value === undefined || refs[ref].value === null || refs[ref].value === '') { 
            refs[ref].$el.scrollIntoView({ behavior: "smooth" });
            refs[ref].setInvalid(true);
            error = true;
          }
        }
      }

      // Verificacion de obligatoriedad en los campos de los pasos internos
      const step11Result = this.$refs.step11?.notifyChanges() || { error: false };
      const step12Result = this.$refs.step12?.notifyChanges() || { error: false };
      const step13Result = this.$refs.step13?.notifyChanges() || { error: false };

      error = error || step11Result.error || step12Result.error || step13Result.error;

      console.log("this.initialData", this.initialData);
      console.log("this.mainData", this.mainData);
      // Se implementa lógica antes de guardar la info, en caso de ser necesario
      return { error: error, data: this.mainData };
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
