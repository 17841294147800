<template>
  <div>
    <div class="px-0 py-6 bg-white rounded-2xl shadow-lg ml-4">
      <FloatingButton
        v-if="showJustOneStep == null"
        @saveDraft="notifyChangesToStepChild"
        label="GUARDAR COMO BORRADOR"
        success="true"
        class="mt-16"
      />

      <div class="flex justify-start">
        <b class="text-left text-2xl mx-2 pb-6 pl-8 text-blue"
          >Paso {{ current_step }}
          {{ showJustOneStep == null ? "de 4" : "" }}</b
        >
      </div>
      <hr />
      <step1_detail
        v-if="current_step == 1"
        ref="step_1"
        :initialData="annual_report.step_1"
        :company="annual_report.company"
        :soloPreview="showJustOneStep != null"
      />
      <step2_detail
        v-if="current_step == 2"
        ref="step_2"
        :initialData="annual_report.step_2"
        :company="annual_report.company"
        :soloPreview="showJustOneStep != null"
      />
      <step3_detail
        v-if="current_step == 3"
        ref="step_3"
        :initialData="annual_report.step_3"
        :company="annual_report.company"
        :soloPreview="showJustOneStep != null"
      />
      <step4_detail
        v-if="current_step == 4"
        ref="step_4"
        :initialData="annual_report.step_4"
        :soloPreview="showJustOneStep != null"
      />

      <div
        v-if="showJustOneStep == null"
        class="w-full grid grid grid-cols-2 gap-6 justify-end items-end p-8"
      >
        <BaseButton
          v-if="current_step == 1"
          @click.native="previousStep"
          label="CANCELAR"
          atype="secondary"
          type="submit"
          class="col-start-1"
          danger="true"
        />
        <BaseButton
          v-if="current_step > 1"
          @click.native="previousStep"
          label="ATRÁS"
          atype="secondary"
          type="submit"
          class="col-start-1"
          danger="true"
        />
        <BaseButton
          v-if="current_step < 4"
          @click.native="nextStep"
          label="SIGUIENTE"
          atype="primary"
          type="submit"
        />
        <BaseButton
          v-if="current_step == 4"
          @click.native="modalVisibleSaveConfirmation = true"
          label="FINALIZAR"
          atype="primary"
          type="submit"
        />
      </div>
      <div
        v-else
        class="w-full grid grid grid-cols-2 gap-6 justify-end items-end p-8"
      >
        <a-button
          class="col-span-2 mx-8"
          type="default"
          shape="round"
          size="large"
          @click.native="goToReportDetail"
        >
          <b class="inline mx-4 font-bold text-xs">ATRÁS</b>
        </a-button>
      </div>
    </div>
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <a-modal v-model="modalVisible" :footer="null" :closable="false">
      <h1 class="mt-2 text-xl text-left text-blue">
        Tienes cambios sin guardar.
      </h1>
      <p class="my-4">
        <b>¿Quieres guardarlos antes de salir? </b>Guardar tus cambios te
        asegura de no perder el trabajo realizado.
      </p>
      <div class="grid grid grid-cols-4 gap-4 mt-10">
        <a-button
          class="col-span-2"
          type="danger"
          shape="round"
          :block="true"
          ghost
          @click="hasChangesModal()"
        >
          CANCELAR
        </a-button>
        <a-button
          class="col-span-2"
          type="primary"
          shape="round"
          :block="true"
          ghost
          @click="hasChangesModal(true)"
        >
          DESCARTAR CAMBIOS
        </a-button>
        <a-button
          class="col-start-2 col-span-2"
          type="primary"
          shape="round"
          :block="true"
          @click="hasChangesModal(true, true)"
        >
          GUARDAR Y SALIR
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="modalVisibleSaveConfirmation"
      :footer="null"
      :closable="false"
    >
      <h1 class="mt-2 text-xl text-left text-blue">Finalizar informe anual.</h1>
      <p class="my-4">
        <b>¿Está seguro de que desea finalizar el registro? </b> Una vez
        confirmado, no podrá editar la información.
      </p>
      <div class="grid grid grid-cols-4 gap-4 mt-10">
        <a-button
          class="col-span-2"
          type="danger"
          shape="round"
          :block="true"
          ghost
          @click="modalVisibleSaveConfirmation = false"
        >
          CANCELAR
        </a-button>
        <a-button
          class="col-span-2"
          type="primary"
          shape="round"
          :block="true"
          @click="nextStep"
        >
          FINALIZAR
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FloatingButton from "@/components/UI/FloatingButton";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import ErrorMessaje from "@/core/error/error_messaje";
import step1_detail from "@/feature/annual_report/presentation/views/steps/step1_detail";
import step1_detail_v2 from "@/feature/annual_report/presentation/views/steps/step1_detail_v2";
import step2_detail from "@/feature/annual_report/presentation/views/steps/step2_detail";
import step3_detail from "@/feature/annual_report/presentation/views/steps/step3_detail";
import step4_detail from "@/feature/annual_report/presentation/views/steps/step4_detail";

const keyMessage = "updatable";

export default {
  components: {
    step1_detail,
    step1_detail_v2,
    step2_detail,
    step3_detail,
    step4_detail,
    FloatingButton,
  },
  data() {
    return {
      document_id: null,
      showJustOneStep: null,
      current_step: 1,
      openJustification: false,
      legend: "",
      modalVisible: false,
      modalVisibleSaveConfirmation: false,
      nextView: null,
      ignoreNextView: false,
      loading: false,
      disabled: false,
      annual_report: {
        id: "",
        step_1: {
          step_1_1: {},
          step_1_2: {
            DCF_finalizo_quejas_periodo_reportado: null,
            _1_2_1_quejas_terminadas_rectificacion: null,
            _1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF:
              null,
            _1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF:
              null,
            _1_2_2_quejas_terminadas_desistimiento: null,
            _1_2_3_quejas_terminadas_inadmision: null,
            _1_2_3_1_causal_legal_aplica_producto_motivo: null,
            _1_2_4_quejas_terminadas_emitida_DCF: null,
            _1_2_4_1_total_quejas_terminadas_decision_emitida_DCF: null,
            _1_2_5_decisiones_DCF_emitidas_favor_entidad: null,
            _1_2_5_total_decision_emitida_favor_entidad: null,
            _1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero:
              null,
            _1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero:
              null,
            _1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero:
              null,
            _1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero:
              null,
          },
          step_1_3: {
            _1_3_1_presentaron_situaciones_incumplimiento_parcial_total_entidad_posterioridad_rectificación_reportado:
              null,
            _1_3_2_1_se_presentaron_casos_entidad_considero_DCF_carecia_competencia:
              null,
            _1_3_2_1_totalidad_casos_cuales_entidad_justifico_causales_no_competencia:
              null,
            _1_3_2_1_causal_legal_aplica_producto_motivo: null,
            _1_3_2_2_entidad_colaboro_DCF_suministro_informacion_requerida:
              null,             
            _1_3_2_3_periodo_reportado_DCF_emitio_pronunciamientos_interes_general:
              null,
            _1_3_2_3_entidad_informo_DCF_aceptacion_o_no_decisiones_defensor_a_favor_consumidor:
              null,
            _1_3_2_3_tiene_decisiones_a_favor_consumidor_no_aceptadas_entidad:
              null,
            _1_3_2_3_tiene_decisiones_a_favor_consumidor_aceptadas_entidad:
              null,
            _1_3_2_4_periodo_reportado_DCF_emitio_pronunciamientos_interes_general:
              null,
            _1_3_2_4_conocimiento_entidad_pronunciamientos_interes_general_emitio_DCF:
              null,
          },
        },
        step_2: null,
        step_3: null,
        step_4: null,
      },
      steps: { 1: "step_1", 2: "step_2", 3: "step_3", 4: "step_4" },
    };
  },
  async created() {
    if (this.showJustOneStep == null) {
      const current_step = this.$route.query.step ?? this.current_step;
      console.log(
        this.$route.query.step,
        this.$route.query.step != this.current_step,
        this.current_step
      );
      if (this.$route.query.step != this.current_step) {
        this.$router.replace({
          query: { step: current_step },
        });
      }
      this.current_step = parseInt(current_step);
    }

    this.document_id = this.$route.params.id;
    let response = await AnnualReportServices.getAnualReport(this.document_id);

    this.annual_report = {
      ...this.annual_report,
      ...response.data[this.document_id],
    };

    this.annual_report.id = this.document_id;
    this.annual_report.step_1.company = this.annual_report.company;
    this.showJustOneStep = this.$route.params.showStepDetail;
    this.ignoreNextView = this.showJustOneStep != null;
    this.current_step = this.showJustOneStep ?? this.current_step;
  },
  methods: {
    goToReportDetail() {
      this.$router.push({
        name: "annual-report-detail",
        params: { id: this.document_id },
      });
    },
    async updateData(step_ref, data, isComplete = false) {
      this.annual_report[step_ref] = data;
      const stepHasChanged =
        this.$refs[this.steps[this.current_step]].hasChanged;

      if (stepHasChanged || isComplete) {
        let req_data = { [step_ref]: data };
        if (isComplete) {
          req_data["complete"] = isComplete;
        }
        this.$message.loading({
          content: "Cargando...",
          keyMessage,
          duration: 10,
        });
        let response = await AnnualReportServices.updateAnualReport(
          req_data,
          this.document_id
        );
        this.$message.destroy();
        if (!response.isSuccess) {
          this.$notification.error({
            message: ErrorMessaje.errorTitle,
            description: response.message,
          });
          return true;
        }
      }
      this.$message.success({ content: "¡Hecho!", keyMessage, duration: 2 });
      return false;
    },
    showToast(msg, result = "info") {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    cleanForm() {
      this.disabled = false;
    },
    nextStep() {
      const step_ref = this.steps[this.current_step];
      let { error } = this.$refs[step_ref].notifyChanges();
      if (error) {
        return;
      }
      this.changeStep(1);
    },
    previousStep() {
      if (this.current_step !== 1) {
        const step_ref = this.steps[this.current_step];
        let { error } = this.$refs[step_ref].notifyChanges();
        if (error) {
          return;
        }
      }

      this.changeStep(-1);
    },
    async notifyChangesToStepChild(isComplete = false) {
      const step_ref = this.steps[this.current_step];
      let { error, data } = this.$refs[step_ref].notifyChanges();

      error = await this.updateData(step_ref, data, isComplete);

      if (typeof this.$refs[step_ref].updateFiles === "function") {
        error = await this.$refs[step_ref].updateFiles(this.document_id);
      }

      return error;
    },
    async changeStep(value) {
      let step = this.current_step + value;
      if (step >= 1 && step <= 4) {
        const error = await this.notifyChangesToStepChild();
        if (!error) {
          this.$router.replace({
            query: { step: step },
          });
          this.current_step = step;
        }
      } else if (
        (step == 5 && this.current_step == 4) ||
        (step == 0 && this.current_step == 1)
      ) {
        const error = await this.notifyChangesToStepChild(
          this.current_step == 4
        );
        this.ignoreNextView = true;
        if (!error) {
          this.$router.push({
            name: "annual-report-list",
          });
        }
      }
    },
    async hasChangesModal(confirmLeave = false, confirmUpdate = false) {
      this.modalVisible = false;
      if (confirmUpdate) {
        const error = await this.notifyChangesToStepChild();
        if (error) return;
      }
      if (confirmLeave) {
        this.confirmRouteLeave();
      }
    },
    confirmRouteLeave() {
      this.nextView();
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
    formOptions: function () {
      return this.$store.state.form;
    },
  },
  beforeRouteLeave(to, from, next) {
    const stepHasChanged = this.$refs[this.steps[this.current_step]].hasChanged;
    this.nextView = next;
    if (!this.ignoreNextView && stepHasChanged) {
      this.modalVisible = true;
    } else {
      this.confirmRouteLeave();
    }
  },
};
</script>