var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Accordion',{key:"astep1",staticClass:"px-0",attrs:{"open":_vm.openAccordions.acc_1,"disable":false,"header":"1. De la función de atención y resolución de quejas"}},[_c('div',{staticClass:"w-full"},[_c('BaseRadio',{ref:"ar_type_11",staticClass:"col-span-2",attrs:{"name":"ar_type_11","label":"¿El DCF recibió quejas de los consumidores financieros contra la entidad en el período reportado?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'acc_11')},model:{value:(
          _vm.initialData.step_1_1
            .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado
        ),callback:function ($$v) {_vm.$set(_vm.initialData.step_1_1
            , "DCF_recibio_quejas_consumidores_contra_entidad_período_reportado", $$v)},expression:"\n          initialData.step_1_1\n            .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado\n        "}}),(
          _vm.initialData.step_1_1
            .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado ==
          1
        )?_c('Step11',{ref:"step11",attrs:{"open":_vm.initialData.step_1_1
            .DCF_recibio_quejas_consumidores_contra_entidad_período_reportado ==
          1,"mainData":_vm.initialData,"soloPreview":_vm.soloPreview}}):_vm._e(),_c('Step12',{ref:"step12",attrs:{"open":_vm.openAccordions.acc_12,"mainData":_vm.initialData,"soloPreview":_vm.soloPreview}}),_c('Step13',{ref:"step13",attrs:{"open":_vm.openAccordions.acc_13,"mainData":_vm.initialData,"soloPreview":_vm.soloPreview,"company":_vm.company}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }