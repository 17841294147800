<template>
    <div class="w-full mt-10 mb-10">

        <Accordion :open="open" :disable="!open" key="step-1.1"
            header="1.1 Reporte estadístico de quejas recibidas por parte del DCF">

            <div class="grid grid-cols-2 gap-x-8">
                <BaseInput ref="step_11" type="number" numeric label="Número total de quejas recibidas por la DCF" :required="true"
                    @blur="onBlurTotalCounter" v-model="mainData.step_1_1.total_quejas_recibidas_DCF"
                    :disabled="soloPreview" />

                <BaseInput type="number" numeric label="Número de quejas registradas instancia DCF" :disabled="true"
                    :value="instanceCounter" />
            </div>

            

        </Accordion>
    </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import TemplateProductInputs from "../template_product_inputs.vue";
import ProductReasonField from "../../../../../annual_report_v2/presentation/components/ProductReasonsField";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";


export default {
    components: {
        TemplateProductInputs, Accordion, ProductReasonField
    },
    props: {
        soloPreview: {
            type: Boolean,
            default: false
        },
        mainData: {

        },
        open: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        instanceCounter: "0"
    }),
    methods: {

        async getCounters() {
            let json = {
                "entity_code": this.mainData.company.sfc_code,
                "products": [],
                "reasons": [],
            }
            console.log('counter json', json)
            let data = await AnnualReportServices.getCounters(json, '?insta_recepcion_id=3');
            if (!data.isSuccess) {
                this.$notification.close("request");
                this.$notification.warning({
                    key: 'request',
                    message: data.message
                });
            }
            return data.data;
        },
        async onBlurTotalCounter(evt, val) {
            if (val) {
                const response = await this.getCounters();
                this.instanceCounter = String(response.complaint_count)
                if (this.mainData.step_1_1.total_quejas_recibidas_DCF !== this.instanceCounter) {
                    this.$notification.close("counter");
                    this.$notification.warning({
                        key: 'counter',
                        message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
                    });
                }
            }
        },
        notifyChanges() {
            let error = false;
            let refs = { ...this.$refs };

            for (let ref in refs) {
                if (
                    (refs[ref] && refs[ref].required) ||
                    refs[ref]?.invalid
                ) {
                    if (refs[ref].value === undefined || refs[ref].value === null || refs[ref].value === '') {
                        refs[ref].$el.scrollIntoView({ behavior: 'smooth' });
                        refs[ref].setInvalid(true);
                        error = true;
                    }
                }
            }
            return { error: error };
        },
    },


}


</script>